<template>
  <div class="head-container">
    <el-input
      v-model="query.id"
      clearable
      placeholder="ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <search-community ref="searchcommunity"></search-community>
    <el-input
      v-model="query.copyInfo"
      clearable
      placeholder="订阅者昵称/MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.signalInfo"
      clearable
      placeholder="信号源昵称/MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.ownerUserName"
      clearable
      placeholder="返佣客户昵称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-date-picker
      v-model="query.startTime"
      type="date"
      placeholder="开始日期"
      class="filter-item"
      style="width: 180px;"
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="query.endTime"
      type="date"
      class="filter-item"
      style="width: 180px;"
      placeholder="结束日期"
    >
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  components: {
    searchCommunity,
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payTypeOptions: [
        {
          label: '支付宝',
          value: 1,
        },
        {
          label: '微信',
          value: 2,
        },
        {
          label: '代币',
          value: 3,
        },
      ],
      priceTypeOptions: [
        {
          label: '订阅费',
          value: 2,
        },
        {
          label: '表现费',
          value: 3,
        },
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
