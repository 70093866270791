<template>
  <div class="app-container equityheader summary-page">
    <eHeader ref="header" :query="query" />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="userName" label="订阅者">
        <template slot-scope="scope">
          <div>{{scope.row.copyUserName}}</div>
          <div>{{scope.row.copyLogin}}</div>
        </template>
      </el-table-column>
      <el-table-column label="信号源">
        <template slot-scope="scope">
          <template v-if="scope.row.signalUserName">
            <div>{{scope.row.signalUserName}}</div>
            <div>{{scope.row.signalLogin}}</div>
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column prop="ownerUserName" label="返佣客户"/>
      <el-table-column prop="amount" label="佣金(USDT)"/>
      <el-table-column label="返佣时间">
        <template slot-scope="scope">
          <span>{{$parseTime(scope.row.time)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import initData from '@/mixins/initData'
  import eHeader from '../../../components/financial/commission/header'
  export default {
    components: {
      eHeader,
    },
    mixins: [initData],
    data() {
      return {
        statistics: {
          "sumAmount": '',// "总余额",
        },
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      });
    },
    methods: {
      beforeInit() {
        this.url = '/community/crm/getCommissionInfo'
        this.params = {
          page: this.page,
          size: this.size,
          id: this.query.id,
          copyInfo: this.query.copyInfo,
          signalInfo: this.query.signalInfo,
          ownerUserName: this.query.ownerUserName,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.query.startTime){
          this.params.startTime = this.$parseTime(this.query.startTime);
        }
        if(this.query.endTime){
          this.params.endTime = this.$parseTime(this.query.endTime);
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 5:
              sums[index] = `${this.statistics.sumAmount}`;
              break;
          }
        });
        return sums;
      },
    }
  }
</script>

<style lang="less" scoped>
.equityheader {

}
</style>
